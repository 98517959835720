/*
 * (C) 2022 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useReducer, useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { Header, Loading } from '../../../../components/Pages/index';
import { Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs/esm/index';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import Avatar from '@mui/material/Avatar';
import { DISTRIBUTION_COLORS } from '../../../../helpers/distribution';
import {
  STATUS_MAPPING,
  STATUS_MAPPING_DESCRIPTIONS,
} from '../../../../components/ContentCard/StatusMapping';
import InfoIcon from '@mui/icons-material/Info';
import { categoryMapping } from '../../../../utilities/CategoryMapping';
import { remark } from 'remark';
import ReactMarkdown from 'react-markdown';
import strip from 'strip-markdown';
import { config } from '../../../../app/Config';
import { getCategories } from '../../../../helpers/api/category';
import {
  GET_CATEGORIES,
  ADD_CATEGORIES,
  SET_ERRORS,
} from '../../../../redux/actions/categories';
import categoriesReducer from '../../../../redux/reducers/categories';
import { VirtualTable } from '@rosm/rosm-ng-components/dist/components/ListContent';

const initialCategoriesState = {
  categories: [],
  loading: false,
  loaded: false,
  error: null,
};

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const HEADERS = [
  { title: '', align: 'center', value: '', width: '10%' },
  { title: 'Title', align: 'left', value: 'title', width: '25%' },
  { title: 'Type', align: 'center', width: '20%' },
  { title: 'ROS Version(s)', align: 'center', width: '5%' },
  { title: 'Custodian', align: 'center', width: '10%' },
  { title: 'Version', align: 'center', width: '5%' },
  { title: 'Status', align: 'center', width: '5%' },
  { title: 'Distribution', align: 'left', width: '5%' },
  { title: 'Last Updated', align: 'right', width: '15%' },
];

const PackageList = ({ rows }) => {
  const getCategoriesData = async () => {
    dispatch({ type: GET_CATEGORIES });
    try {
      const response = await getCategories();
      const data = await response.json();
      if (response.status === 200 && data) {
        dispatch({ type: ADD_CATEGORIES, data });
      } else {
        dispatch({
          type: SET_ERRORS,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      console.log('error', error);
      dispatch({
        type: SET_ERRORS,
        error: 'error',
      });
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  const [categoryMappings, setCategoryMappings] = useState(null);
  const [descriptions, setDescriptions] = useState({});

  const [state, dispatch] = useReducer(
    categoriesReducer,
    initialCategoriesState
  );
  const { categories, loading, error, loaded } = state;

  const stripMarkdown = async (s) => {
    return await remark()
      .use(strip)
      .process(s.replace(/\t/g, ' ')) // replace tabs to ignore markdown monospace (otherwise all monospace text gets stripped)
      .then((s) => {
        const newDesc = s.value.replace(/(?:\r\n|\r|\n)/g, ' ');
        return newDesc.length > 150
          ? `${newDesc.substring(0, 150)}...`
          : newDesc;
      });
  };

  useEffect(() => {
    async function cleanDescriptions() {
      let descs = {};
      for (const r in rows) {
        const data = rows[r];
        const desc = await stripMarkdown(data.description);
        descs[data._id] = desc;
      }
      setDescriptions(descs);
    }
    cleanDescriptions();
  }, [rows]);

  useEffect(() => {
    let rosmCategories = [];
    categories.map((category) => {
      rosmCategories.push({
        title: category.name,
        label: category.name,
        value: category.categoryId,
        status: 1,
        description: category.name,
        img: `${category.primary.toLowerCase()}.png`,
      });
    });
    setCategoryMappings(rosmCategories);
  }, [categories]);

  let navigate = useNavigate();

  if (error) {
    return (
      <>
        <Header title={'Packages'} />
        <h4>Error Loading Content</h4>
      </>
    );
  }
  if (
    loading ||
    !loaded ||
    categories.length === 0 ||
    !categoryMappings ||
    categoryMappings.length === 0
  ) {
    return <Loading />;
  }

  const handleClick = (row) => {
    navigate(`/packages/${row._id.toString()}`);
  };

  const headerItems = () =>
    HEADERS.map((header) => {
      return (
        <TableCell
          style={{
            width: header.width,
            minWidth: header.width,
            maxWidth: header.width,
            padding: '8px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          align={header.align}
        >{`${header.title}`}</TableCell>
      );
    });

  const rowItems = (index, row) => {
    const d = dayjs(row.updatedDate).utc('z');
    const d1 = dayjs().utc('z');
    const diff = d.diff(d1);
    const duration = dayjs.duration(diff).humanize(true);

    const title =
      row.title.length > 50 ? `${row.title.substring(0, 50)}...` : row.title;

    const categoryData = categoryMappings[row.category];
    const categoryImageUrl =
      config.PUBLIC_URL + `/images/categories/${categoryMapping[row.category]}`;

    const tagChips = row.tags.map((tag) => {
      return (
        <Chip
          color='primary'
          sx={{
            '& .MuiChip-label': {
              paddingLeft: '4px',
              paddingRight: '4px',
            },
            marginRight: '4px',
            borderRadius: '4px',
            height: '21px',
          }}
          label={tag}
        />
      );
    });

    let rosVersionsLabel = 'N/A';
    let rosVersionsListing = [];
    if (row.compatibleRosVersions && row.compatibleRosVersions.length !== 0) {
      if (row.compatibleRosVersions.length > 1) {
        row.compatibleRosVersions?.map((version) => {
          rosVersionsListing.push(version);
        });
        rosVersionsListing = rosVersionsListing.join(', ');
        rosVersionsLabel = `${row.compatibleRosVersions.length} versions`;
      } else {
        rosVersionsLabel = row.compatibleRosVersions[0];
        rosVersionsListing = false;
      }
    }

    const rosVersions = (
      <Chip
        color={
          rosVersionsLabel === 'N/A'
            ? 'warning'
            : row.compatibleRosVersions.length !== 1
            ? 'success'
            : 'primary'
        }
        sx={{
          '& .MuiChip-label': {
            paddingLeft: '4px',
            paddingRight: '4px',
          },
          marginRight: '4px',
          borderRadius: '4px',
          height: '21px',
        }}
        label={rosVersionsLabel}
      />
    );

    return (
      <>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='left'
        >
          <div style={{ marginTop: '4px' }}>
            <img
              component='img'
              height='40px'
              width='40px'
              src={categoryImageUrl}
              alt={categoryData}
            />
          </div>
        </TableCell>
        <TableCell
          style={{
            width: '25%',
            minWidth: '25%',
            maxWidth: '25%',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='left'
        >
          <div>
            <div style={{ marginBottom: '4px' }}>{title}</div>
            <div style={{ marginBottom: '4px' }}>
              <ReactMarkdown
                children={descriptions[row._id.toString()]}
                remarkPlugins={[strip]}
              />
            </div>
            <div>{tagChips}</div>
          </div>
        </TableCell>
        <TableCell
          style={{ width: '20%', minWidth: '20%', maxWidth: '20%' }}
          align='center'
        >
          <Chip
            color='primary'
            sx={{
              '& .MuiChip-label': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
              marginRight: '4px',
              borderRadius: '4px',
              height: '21px',
            }}
            label={categoryData.title}
          />
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          {rosVersionsListing && (
            <>
              <Tooltip title={rosVersionsListing}>{rosVersions}</Tooltip>
            </>
          )}
          {!rosVersionsListing && <>{rosVersions}</>}
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          {row.custodian}
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          <Tooltip title={row.version ? row.version : 'No version available.'}>
            <Typography>
              {row.version
                ? row.version.substring(0, 7) +
                  (row.version.length > 7 ? '...' : '')
                : '---'}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          <Tooltip title={STATUS_MAPPING_DESCRIPTIONS[row.status]}>
            {STATUS_MAPPING[row.status]}
          </Tooltip>
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          <div style={{ marginLeft: '15px' }}>
            <Avatar
              sx={{ bgcolor: DISTRIBUTION_COLORS[row.distributionStatement] }}
            >
              {row.distributionStatement}
            </Avatar>
          </div>
        </TableCell>
        <TableCell
          style={{
            width: '15%',
            minWidth: '15%',
            maxWidth: '15%',
          }}
          align='right'
        >
          <div style={{ display: 'inline-flex' }}>
            <Tooltip title={d.utc().local().format('YYYY-MM-DD')}>
              <InfoIcon />
            </Tooltip>
            <div style={{ paddingLeft: '4px', marginTop: '2px' }}>
              {duration}
            </div>
          </div>
        </TableCell>
      </>
    );
  };

  return (
    <VirtualTable
      styles={{ height: 'calc(100vh - 248px)' }}
      rows={rows}
      handleClick={handleClick}
      headerItems={headerItems}
      rowItems={rowItems}
    />
  );
};

PackageList.propTypes = {};

export default PackageList;
