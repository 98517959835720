/*
 * (C) 2022 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ForwardIcon from '@mui/icons-material/Forward';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

import {
  getRosmPackagesList,
  getRosmPackagesByInstantiationList,
  getRosmInstantiationList,
} from '../../../helpers/api/rosmRequests';
import PackageList from './components/PackageList';

import {
  Header,
  MissingContent,
  Loading,
} from '../../../components/Pages/index';

const ROSM_PACKAGES_ACTIONS = {
  FETCH_ROSM_PACKAGES: 'FETCH_ROSM_PACKAGES',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
const rosmPackagesReducer = (state, action) => {
  switch (action.type) {
    case ROSM_PACKAGES_ACTIONS.ROSM_PACKAGES_ACTIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ROSM_PACKAGES_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        rosmPackagesList: action.data,
      };
    }
    case ROSM_PACKAGES_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialRosmPackagesState = {
  rosmPackagesList: [],
  loading: false,
  loaded: false,
  error: null,
};

function PackagesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  let instantiationId;
  let incomingUrlParams = {};
  let hasIncomingParameters = false;
  const [state, dispatch] = useReducer(
    rosmPackagesReducer,
    initialRosmPackagesState
  );
  const { rosmPackagesList, loading, error, loaded } = state;
  const [filterValues, setFilterValues] = useState({
    instantiations: null,
  });
  const [filters, setFilters] = useState({
    instantiation: 0,
  });

  /**
   * Returns an object of the incoming URL query parameters
   * @returns object
   */
  const getIncomingUrlParams = () => {
    for (const [key, value] of searchParams.entries()) {
      incomingUrlParams[key] = value;
    }
    return incomingUrlParams;
  };

  /**
   * Load the listing of packages
   */
  const loadList = () => {
    dispatch({ type: ROSM_PACKAGES_ACTIONS.FETCH_ROSM_PACKAGES });
    // TODO move elsewhere
    const getList = async () => {
      try {
        let response = [];
        let data;
        if (filters.instantiation) {
          response = await getRosmPackagesByInstantiationList(
            filters.instantiation
          );
          data = await response.json();
          data = data.data;
        } else {
          response = await getRosmPackagesList();
          data = await response.json();
        }

        if (response.status === 200) {
          dispatch({ type: ROSM_PACKAGES_ACTIONS.SUCCESS, data });
          return;
        }
        dispatch({
          type: ROSM_PACKAGES_ACTIONS.ERROR,
          error: data?.error ? data.error : response.error,
        });
      } catch (error) {
        dispatch({ type: ROSM_PACKAGES_ACTIONS.ERROR, error: 'error' });
      }
    };
    getList();
  };

  useEffect(() => {
    if (!loading && !loaded && !error) {
      const loadFilterValues = async () => {
        let filterInstantiations = await getRosmInstantiationList();
        filterInstantiations = await filterInstantiations.json();
        let instantiationsArray = filterInstantiations.map((ins) => ({
          id: ins._id,
          title: ins.title,
        }));
        setFilterValues({ instantiations: instantiationsArray });
      };
      loadFilterValues();
      // Manage incoming filter parameters
      getIncomingUrlParams();
      hasIncomingParameters = JSON.stringify(incomingUrlParams) !== '{}';
      if (hasIncomingParameters) {
        // TODO: Replace w/ proper universal
        if (incomingUrlParams.hasOwnProperty('filterInstantiationId')) {
          instantiationId = incomingUrlParams.filterInstantiationId;
          setFilters({ instantiation: instantiationId });
          return true;
        }
      }
      loadList();
    }
  }, []);

  useEffect(() => {
    loadList();
    if (filters.instantiation) {
      const updatedParams = {
        filterInstantiationId: filters.instantiation,
      };
      setSearchParams(updatedParams);
    } else {
      setSearchParams({});
    }
  }, [filters]);

  /**
   * Shows the header packages filter options
   * @returns component
   */
  const FilterOptions = () => {
    const handleSetFiltersOption = (event) => {
      setFilters({ instantiation: event.target.value });
    };
    const instantiationFilterValues = filterValues.instantiations;
    return (
      <>
        <Grid container spacing={2} sx={{ pt: 1, pb: 1 }}>
          <Grid item>
            <Typography variant='inlineFilter'>Filters</Typography>
          </Grid>
          <Grid item>
            {instantiationFilterValues && (
              <FormControl fullWidth>
                <InputLabel
                  id='instantiationFilter-label'
                  sx={{ fontSize: 20, fontWeight: 'bold' }}
                >
                  Instantiation
                </InputLabel>
                <Select
                  sx={{
                    width: '300px',
                    backgroundColor: 'white',
                    '& .MuiInputBase-input': {
                      padding: '12px 4px 4px 4px',
                    },
                  }}
                  labelId='instantiationFilter-label'
                  id='instantiationFilter'
                  value={filters.instantiation}
                  label='Instantiation'
                  onChange={handleSetFiltersOption}
                  variant='filled'
                >
                  <MenuItem value={0}>All</MenuItem>
                  {instantiationFilterValues.map((instantiation) => (
                    <MenuItem value={instantiation.id} key={instantiation.id}>
                      {instantiation.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ mb: 1 }} />
      </>
    );
  };

  return (
    <div>
      <div>
        {error && <h4>error</h4>}
        {loading ? (
          <Loading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <FilterOptions />
            <Header title='PACKAGES' />
            {rosmPackagesList.length === 0 && loaded && <MissingContent />}
            {rosmPackagesList.length > 0 && (
              <PackageList rows={rosmPackagesList} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PackagesPage;
